const region = 'oss-cn-hangzhou'
const endpoint = 'oss-cn-hangzhou.aliyuncs.com'
const accessKeyId = 'LTAI4FxHHM4MaUWgrfsxXFpA'
const accessKeySecret = 'Dyd2TuPKwf2LdEQEtI97LaQuFU8Jyz'
const bucket = 'sdtestabc'
const warename = 'admin/coursewareTeacher/'

export default {
    region,
    endpoint,
    accessKeyId,
    accessKeySecret,
    bucket,
    warename
}