<template>
  <div class="login-wrap login-content">
    <div class="login-text">
      <h3>Weclome</h3>
      <h5>书丁教师后台服务系统</h5>
    </div>
    <div class="login-box">
      <h5 class="h5-title">登录</h5>
      <el-form ref="loginForm" :model="form" :rules="rules" label-width="40px">
        <el-form-item label="" prop="phoneNumber">
          <el-input
            type="text"
            maxlength="11"
            suffix-icon="el-icon-mobile-phone"
            placeholder="手机号"
            v-model="form.phoneNumber"
          />
        </el-form-item>
        <el-form-item label="" prop="code">
          <div class="verify-wrapper">
            <el-input
              type="text"
              maxlength="6"
              suffix-icon="el-icon-lock"
              placeholder="验证码"
              v-model="form.code"
            />
            <el-button
              class="btn-orange"
              :disabled="disabled"
              @click="getCode"
              >{{ valiBtn }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item>
          <button class="d-btn-orange" @click.prevent="onSubmit('loginForm')">
            登录
          </button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
//import { login } from "@/service/api.js";
export default {
  name: "login",
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      form: {
        phoneNumber: "",
        code: "",
      },
      autoLogin: false,
      rules: {
        phoneNumber: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      dialogVisible: false, // 对话框显示和隐藏
      valiBtn: "获取验证码",
      disabled: false,
    };
  },
  methods: {
    //获取验证码
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["loginForm"].validateField("phoneNumber", (err) => {
        if (err) {
          console.log("未通过");
          return;
        } else {
          // console.log("已通过");
          this.tackBtn(); //验证码倒数60秒
          let fd = new FormData(); //POST 请求需要 转化为Form
          fd.append("phoneNumber", this.form.phoneNumber);
          fd.append("NeedCheck", 2);

          // login({phoneNumber:this.phoneNumber}).then((data) => {
          //   console.log(data)
          // });
          this.$axios({
            method: 'post',
            data: fd,
            url: `/teacher/login/sendSms`,
          }).then((res)=>{
           // console.log(res)
          })
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    onSubmit(formName) {
      //点击登录 验证手机& 验证码是否符合条件
      this.$refs[formName].validate((valid) => {
        // 为表单绑定验证功能
        if (valid) {
          let fd = new FormData();
          fd.append("phoneNumber", this.form.phoneNumber);
          fd.append("code", this.form.code);
          this.$axios({
            method: "POST",
            data: fd,
            url: `/teacher/login/loginByPhone`,
          }).then((res) =>{
            if (res.data.code =='0000') {
              var token = res.data.data.token;
              var id=res.data.data.user.id;
              var mobile=res.data.data.user.mobile;
              var name=res.data.data.user.name;
              var section=res.data.data.user.section;
              localStorage.setItem("token", token);
              localStorage.setItem("userId", id);
              localStorage.setItem("name",name);
              localStorage.setItem("section",section);
              localStorage.setItem("mobile",mobile);
              this.$message({
                showClose: true,
                message: "登录成功",
                type: "success",
              });
              this.$router.push({ path: "/index" });
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "warning",
              });
            }
          });
        } else {
          this.dialogVisible = true;
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>