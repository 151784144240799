import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const actions = {}
const mutations = {
  handleUserId: (state, user_id) => {
    state.user_id = user_id
    localStorage.setItem('user_id', user_id)
  }
}
const state = {
  user_id: '' || localStorage.getItem('user_id')
}
// getters 只会依赖 state 中的成员去更新
const getters = {
  userName: (state) => state.user_id
}
 
const store = new Vuex.Store({
  actions,
  mutations,
  state,
  getters
})
export default store;