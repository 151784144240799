import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

if (process.env.NODE_ENV == 'development') {//开发环境
  axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {//生产环境
  axios.defaults.baseURL = 'http://teacherapi.shudingkj.com/';
}

axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$axios=axios;

axios.interceptors.response.use(response => {
  if (response) {
    switch (response.data.code) {
      case '0010': 
        localStorage.clear();     
        location.href='/'
    }
  }
  return response;
}, error => {
  return Promise.reject(error.response.data) //返回接口返回的错误信息
})
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.scss';
Vue.config.productionTip = false
// 引入echarts
import echarts from 'echarts'
 Vue.prototype.$echarts = echarts
import configCommon from '@/utils/config'
Vue.prototype.Common = configCommon //oss等系统参数
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
