<template>
  <header class="header">
    <div class="header-title">
      你好，管理员<span class="username">{{name}}</span>
      <a class="loginOut-btn" href="javascript:;" @click="loginOut"></a>
    </div>
  </header>
</template>
<script>
export default {
  name: "headbar",
  props:['name'],
  data() {
    return {};
  },
  methods: {
    loginOut() {
      this.$confirm("真的确定要登出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          localStorage.removeItem("token");
          this.$router.push({ path: "/login" });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  text-align: left;
  background: none;
  padding-left: 0;
  height: auto !important;
  .header-title {
    height: 64px;
    line-height: 64px;
    font-size: 14px;
    span {
      color: #54bb65;
      margin-left: 6px;
      position: relative;
      &:after {
        display: block;
        content: "";
        position: absolute;
        height: 2px;
        background: #54bb65;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    .loginOut-btn {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../assets/images/loginOut.png) no-repeat center;
      margin-left: 20px;
      position: relative;
      top: 9px;
    }
  }
}
</style>