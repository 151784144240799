<template>
  <div class="wrap">
    <el-container class="clearfix">
      <leftnav />
      <div class="content">
        <headbar :name=name />
        <el-main>
          <div class="main-wrap clearfix">
            <div class="content-innerWrap">
              <router-view></router-view>
            </div>
          </div>
        </el-main>
      </div>
    </el-container>
  </div>
</template>

<script>
import leftnav from "@/components/leftnav.vue";
import headbar from "@/components/header.vue";
export default {
  name: "Home",
  components: {
    leftnav,
    headbar,
  },
  dada(){
    return{
      name:''
    }
  },
  created(){
    this.name=window.localStorage.getItem('name');
  }
};
</script>