<template>
     <el-aside>
        <div class="logo">
          <router-link to="/index" tag="a" @click.native="goHome">
            <img src="../assets/images/logo.png" alt="" />
          </router-link>
        </div>
        <div class="slider-menu">
          <el-menu
             class="el-menu-vertical-demo"
             @open="handleOpen"
             @close="handleClose"
             router 
             :default-active="activeIndex"
             @select="handleSelect"
             >
               <el-menu-item v-for="menu in allmenu" :key="menu.menuid" :index="menu.url">
                    <i></i>
                    <span slot="title">{{menu.menuname}}</span>
              </el-menu-item>
            </el-menu>
        </div>
      </el-aside>
</template>

<script>
export default {
  name: "leftnav",
  data() {
    return {
      allmenu: [],
      activeIndex: '/'
    };
  },
  watch: {
	'$route' () {
		this.handleSelect(this.activeIndex)
    }
},
  methods: {
    handleOpen(key, keyPath) {
      
    },
    handleClose(key, keyPath) {
      
    },
    handleSelect (index) {
      	this.activeIndex =index
    },
    goHome(){
      this.activeIndex="/index";
    }
  },
  created() {
    this.activeIndex=this.$route.matched[1].path;
    let res = {
      success: true,
      data: [
        {
          menuid: 1,
          menuname: "概览",
          url: "/index",
        },
        {
          menuid: 2,
          menuname: "课程管理",
          url: "/class",
        },
        {
          menuid: 3,
          menuname: "作品管理",
          url: "/work",
        },
        {
          menuid: 4,
          menuname: "收益管理",
          url: "/profits",
        },
        {
          menuid: 5,
          menuname: "个人中心",
          url: "/user",
        },
      ],
      msg: "success",
    };
    this.allmenu = res.data;
  },
};
</script>

<style lang="scss">
//左边导航
.el-container{
  position: relative;
}
.el-aside {
  background-color: #d3dce6;
  width: (300%/18.8) !important;
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  position: absolute;
  left:0;
  top:0;
  min-height: 700px;
  .logo {
    height: 120px;
    display: flex;
    padding-left: 35px;
    align-items: center;
    .img {
      display: block;
    }
  }
  .slider-menu {
    .el-menu-vertical-demo {
      background: #fff !important;
      .el-menu-item {
        padding-left: 78px !important;
        height: 72px;
        line-height: 72px;
        span {
          font-size: 19px;
          color: #999;
        }
      }
    }
    .el-menu {
      border: none;
    }
    li {
      -webkit-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      -o-transition: 0.5s all;
      transition: 0.5s all;
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 0;
        height: 0%;
        left: 0;
        top: 0;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        transition: 0.3s all;
      }
      &:hover {
        background: #f1fbe7;
      }
      &.is-active {
        background-color: #f1fbe7;
        position: relative;
        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 10px;
          height: 100%;
          background: #54bb65;
          left: 0;
          top: 0;
        }
      }
      i {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 35px;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        transition: 0.3s all;
      }
      &:nth-child(1) {
        i {
          background: url(../assets/images/home.png) no-repeat center;
        }
        &.is-active {
          i {
            background: url(../assets/images/homeHover.png) no-repeat center;
          }
        }
      }
      &:nth-child(2) {
        i {
          background: url(../assets/images/class.png) no-repeat center;
        }
        &.is-active {
          i {
            background: url(../assets/images/classHover.png) no-repeat center;
          }
        }
      }
      &:nth-child(3) {
        i {
          background: url(../assets/images/works.png) no-repeat center;
        }
         &.is-active {
          i {
            background: url(../assets/images/worksHover.png) no-repeat center;
          }
        }
      }
      &:nth-child(4) {
        i {
          background: url(../assets/images/profit.png) no-repeat center;
        }
        &.is-active {
          i {
            background: url(../assets/images/profitHover.png) no-repeat center;
          }
        }
      }
      &:nth-child(5) {
        i {
          background: url(../assets/images/users.png) no-repeat center;
        }
        &.is-active {
          i {
            background: url(../assets/images/usersHover.png) no-repeat center;
          }
        }
      }
    }
  }
}
</style>