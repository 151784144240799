import Vue from 'vue'
import VueRouter from 'vue-router'
import {checkToken} from '@/service/api';

// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'home'
    },
    meta: {
      login: true
    },
  }, {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '',
    component: index,
    meta: {
      login: true
    },
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import('../views/home'),
        meta: {
          login: true
        },
      }, {
        path: '/class',
        component: () => import('../views/class/classAdmin'),
        meta: {
          login: true
        },
        children: [
          {
            path: '',
            name: 'class',
            component: () => import('../views/class/class.vue'),
            meta: {
              login: true
            },
          },
          {
            path: 'classList',
            name: 'classList',
            component: () => import('../views/class/classList.vue'),
            meta: {
              login: true
            },
          },
        ]
      },
      {
        path: '/work',
        name: 'work',
        component: () => import('../views/work'),
        meta: {
          login: true
        },
      },
      {
        path: '/profits',
        component: () => import('../views/profits'),
        meta: {
          login: true
        },
        children: [
          {
            path: '',
            name: 'profitList',
            component: () => import('../views/profits/profitList.vue'),
            meta: {
              login: true
            },
          },
        ]
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user'),
        meta: {
          login: true
        },
      }
    ]
  },
  {
    path: '*',
    title: '未找到',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  // // 访问登录页，放行
  if (to.path === '/login') return next()
  // 获取token
  // 没有token, 强制跳转到登录页
  if (!token) return next('/login')
  next()
})



export default router
